let ENV = import.meta.env.MODE;
if (!ENV) {
    ENV = 'local_dev';
}

const JET_BUILD_VERSION = import.meta.env.VITE_JET_BUILD_VERSION || '';

let settings = {
    ENV,
    IS_PRODUCTION: ENV === 'production',
    IS_LOCAL_DEV: ENV === 'local_dev',
    JET_BUILD_VERSION,
};

if (ENV === 'local_dev') {
    settings = {
        ...settings,
        API_BASE_URL: 'http://backend.jethr.localhost',
        ACCOUNTANT_BASE_URL: 'http://accountant.jethr.localhost',
        ADMIN_BASE_URL: 'http://admin.jethr.localhost',
        BACKOFFICE_BASE_URL: 'http://backoffice.jethr.localhost',
        EMPLOYEE_BASE_URL: 'http://employee.jethr.localhost',
        JET_ENGINE_BASE_URL: 'http://jpe.jethr.localhost',
        JET_STUDIO_BASE_URL: 'http://studio.jethr.localhost',
        JET_STUDIO_CUSTOMER_BASE_URL: 'http://clienti.jethr.localhost',
        GOOGLE_MAPS_API_KEY: 'AIzaSyB6_NtOhadopsrP5QAqn3s0vFHDvcssAVo',
        GOOGLE_TAG_MANAGER: {
            containerId: 'GTM-TT26GXL9',
            debug: true,
            enabled: false,
        },
        SENTRY_SETTINGS: {
            enabled: false,
            environment: ENV,
            dsn: 'https://9dd736ef92416cab8065eff87fc96599@o4505465961644032.ingest.sentry.io/4505720069357568',
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.0, // Don't capture sessions without errors
            replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with errors
            // tracePropagationTargets binds backend errors to frontend sessions
            tracePropagationTargets: [/^http:\/\/backend\.jethr\.localhost\/api\//],
        },
        SSO_URL: 'http://sso.jethr.localhost',
        NOT_SUPPORTED_IN_APP_URL: 'http://sso.jethr.localhost/#/app-non-supportata',
        // eslint-disable-next-line max-len, vue/max-len
        STRIPE_PUBLISHABLE_KEY:
            'pk_test_51N3nhDEAwc1JH5vW4wUVys8T6fCg8E0RebFSwowbVHglzaxPbw00vftkMeZzQd8e48HkAkv8LiYAhpc9tlj26Sqr001jsbRkPI',
    };
} else if (ENV === 'production') {
    settings = {
        ...settings,
        API_BASE_URL: 'https://backend.jethr.com',
        ACCOUNTANT_BASE_URL: 'https://accountant.jethr.com',
        ADMIN_BASE_URL: 'https://admin.jethr.com',
        BACKOFFICE_BASE_URL: 'https://backoffice.jethr.com',
        EMPLOYEE_BASE_URL: 'https://employee.jethr.com',
        JET_ENGINE_BASE_URL: 'https://jpe.jethr.com',
        JET_STUDIO_BASE_URL: 'https://studio.jethr.com',
        JET_STUDIO_CUSTOMER_BASE_URL: 'https://clienti.jethr.com',
        GOOGLE_MAPS_API_KEY: 'AIzaSyBruKajpmkxDpai0hJEZcIkcA8kRXgYtGI',
        GOOGLE_TAG_MANAGER: {
            containerId: 'GTM-NF6JWDV',
            debug: false,
            enabled: true,
        },
        SENTRY_SETTINGS: {
            enabled: true,
            environment: ENV,
            dsn: 'https://edcad8424af5c24ecc5a2a9d3152d95e@o4505465961644032.ingest.sentry.io/4505720043667456',
            // Performance Monitoring
            tracesSampleRate: 0.05, // Capture 5% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.0, // Don't capture sessions without errors
            replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with errors
            // tracePropagationTargets binds backend errors to frontend sessions
            tracePropagationTargets: [/^https:\/\/backend\.jethr\.com\/api\//],
            release: `jet_spa-prod@${JET_BUILD_VERSION}`,
        },
        SSO_URL: 'https://sso.jethr.com',
        NOT_SUPPORTED_IN_APP_URL: 'https://sso.jethr.com/#/app-non-supportata',
        // eslint-disable-next-line max-len, vue/max-len
        STRIPE_PUBLISHABLE_KEY:
            'pk_live_51N3nhDEAwc1JH5vWnE434ZxZeTCfyxKBMHL36Tk7EB8yeKDXBoD4e9IAB3jZk5TCSzgwbHjdJxH8NZvspzN7MHNV00YqAj3xT1',
    };
} else if (ENV === 'staging') {
    const [_spa, stagingIdx] = location.hostname.split('.', 2); // SPA.IDX.jethr.dev
    settings = {
        ...settings,
        API_BASE_URL: `https://backend.${stagingIdx}.jethr.dev`,
        ACCOUNTANT_BASE_URL: `https://accountant.${stagingIdx}.jethr.dev`,
        ADMIN_BASE_URL: `https://admin.${stagingIdx}.jethr.dev`,
        BACKOFFICE_BASE_URL: `https://backoffice.${stagingIdx}.jethr.dev`,
        EMPLOYEE_BASE_URL: `https://employee.${stagingIdx}.jethr.dev`,
        JET_ENGINE_BASE_URL: `https://jpe.${stagingIdx}.jethr.dev`,
        JET_STUDIO_BASE_URL: `https://studio.${stagingIdx}.jethr.dev`,
        JET_STUDIO_CUSTOMER_BASE_URL: `https://clienti.${stagingIdx}.jethr.dev`,
        GOOGLE_MAPS_API_KEY: 'AIzaSyB6_NtOhadopsrP5QAqn3s0vFHDvcssAVo',
        GOOGLE_TAG_MANAGER: {
            containerId: 'GTM-TT26GXL9',
            debug: true,
            enabled: true,
        },
        SENTRY_SETTINGS: {
            enabled: true,
            environment: ENV,
            dsn: 'https://a2ba4d884e2cab6aa51b5a4422d04eb6@o4505465961644032.ingest.sentry.io/4505720826036224',
            // Performance Monitoring
            tracesSampleRate: 0.1, // Capture 10% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.0, // Don't capture sessions without errors
            replaysOnErrorSampleRate: 1.0, // Capture 100% of sessions with errors
            // tracePropagationTargets binds backend errors to frontend sessions
            tracePropagationTargets: [/^https:\/\/backend\.jethr\.dev\/api\//],
        },
        SSO_URL: `https://sso.${stagingIdx}.jethr.dev`,
        NOT_SUPPORTED_IN_APP_URL: `https://sso.${stagingIdx}.jethr.dev/#/app-non-supportata`,
        // eslint-disable-next-line max-len, vue/max-len
        STRIPE_PUBLISHABLE_KEY:
            'pk_test_51N3nhDEAwc1JH5vW4wUVys8T6fCg8E0RebFSwowbVHglzaxPbw00vftkMeZzQd8e48HkAkv8LiYAhpc9tlj26Sqr001jsbRkPI',
    };
}

export const DRAFT_PAYSLIPS_MANAGEMENT_ENABLED = false;

export default settings;

export const CONTRACTOR_INVOICES_FEATURE_FLAG = ENV !== 'production';
