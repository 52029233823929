import {end} from '@jetCommon/api.js';
import AccountantApiCommon from '@jetCommon/api/accountant.js';
import AccountingOfficeAddressApiCommon from '@jetCommon/api/accounting-office-address.js';
import AccountingOfficeApiCommon from '@jetCommon/api/accounting-office.js';
import AuthApiCommon from '@jetCommon/api/auth.js';
import CompanyApiCommon from '@jetCommon/api/company.js';
import CompanyDocumentApi from '@/api/company-document.js';
import EmployeeApiCommon from '@jetCommon/api/employee.js';
import EmployeeMonthlyReportApiCommon from '@jetCommon/api/employee-monthly-report.js';
import ExchangeRatesApiCommon from '@jetCommon/api/exchange-rates.js';
import ExpenseApiCommon from '@jetCommon/api/expense.js';
import MonthlyReportApi from '@jetCommon/api/monthly-report.js';
import NotificationsApiCommon from '@jetCommon/api/notification.js';
import PayrollApiCommon from '@jetCommon/api/payroll.js';
import PayslipApiCommon from '@jetCommon/api/payslip.js';
import PersonApiCommon from '@jetCommon/api/person.js';
import SetupApi from '@/api/setup.js';
import UploadedFilesApiCommon from '@jetCommon/api/uploadedFiles.js';

export const api = {
    end,
    accountants: new AccountantApiCommon(),
    accountingOffices: new AccountingOfficeApiCommon(),
    accountingOfficeAddress: new AccountingOfficeAddressApiCommon(),
    auth: new AuthApiCommon(),
    companies: new CompanyApiCommon(),
    companyDocuments: new CompanyDocumentApi(),
    notifications: new NotificationsApiCommon(),
    employees: new EmployeeApiCommon(),
    employeeMonthlyReports: new EmployeeMonthlyReportApiCommon(),
    exchangeRates: new ExchangeRatesApiCommon(),
    expenses: new ExpenseApiCommon(),
    monthlyReports: new MonthlyReportApi(),
    persons: new PersonApiCommon(),
    payrolls: new PayrollApiCommon(),
    payslips: new PayslipApiCommon(),
    setup: new SetupApi(),
    uploadedFiles: new UploadedFilesApiCommon(),
};
