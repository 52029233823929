import {
    addDays,
    format,
    intervalToDuration,
    isFuture,
    isPast,
    isToday,
    isValid,
    parse,
    parseISO,
    formatDistance,
    isWeekend,
    isYesterday,
    differenceInDays,
} from 'date-fns';
import {capitalizeFirstLetter, pluralize} from '@jetCommon/helpers/text.js';
import {it} from 'date-fns/locale';

const weekDays = ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'];

function parseDate(isoDate) {
    // '2023-04-26' -> date instance
    return parse(isoDate, 'yyyy-MM-dd', new Date());
}

function parseDatetime(isoDatetime) {
    // '2023-04-26T10:30:00+02:00 -> datetime instance
    return parseISO(isoDatetime);
}

function formatDateToIta(date) {
    return format(date, 'dd/MM/yyyy');
}

function formatTimeToIta(date) {
    return format(date, 'HH:mm');
}

/**
 * @name formatDateToISOWithoutTimezone
 * @description
 * Converts a date to the format used by the backend.
 * @param {Date} date
 * @returns {string}
 */
function formatDateToISOWithoutTimezone(date) {
    return format(date, 'yyyy-MM-dd');
}

/**
 * @name intervalToTimeString
 *
 * @description
 * Converts an interval object to a time string.
 * Interval object is the same used as input of date-fns' intervalToDuration function.
 * Hours are not showed if interval is lower than 1 hour.
 * Does not support days, months or years.
 *
 * @example
 * intervalToTimeString({
 *   start: new Date(2023, 10, 3, 12, 10, 0),
 *   end: new Date(2023, 10, 3, 14, 30, 0)
 * })
 * // => '02:20:00'
 */
function intervalToTimeString(interval) {
    const duration = intervalToDuration(interval);

    let intervalString = `${duration.minutes.toString().padStart(2, '0')}:${duration.seconds
        .toString()
        .padStart(2, '0')}`;
    if (duration.hours > 0) {
        intervalString = `${duration.hours.toString().padStart(2, '0')}:${intervalString}`;
    }
    return intervalString;
}

function isoDateToItaVerbose(isoDate, options = null) {
    // '1970-01-01' -> '1 gennaio 1970'

    // Note: according to MDN docs, toLocaleString is not efficient when called multiple times with same arguments
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    // FixMe: consider upgrading to (Intl.DateTimeFormat).format
    return new Date(isoDate).toLocaleString('it-IT', options || {month: 'long', day: 'numeric', year: 'numeric'});
}

function isoDateToItaVerboseWithDay(isoDate, shortMonth = false) {
    // '1970-01-01' -> 'Domenica, 1 Gennaio 1970' or 'Domenica, 1 Gen 1970' if shortMonth=true
    const formatString = shortMonth ? 'EEEE, d MMM yyyy' : 'EEEE, d MMMM yyyy';
    return format(new Date(isoDate), formatString, {locale: it}).replace(/\b\w/g, l => l.toUpperCase());
}

/**
 * @name isoDateToItaVerboseWithDayAndNoYear
 * @description
 * Converts an ISO date to a verbose Italian date without the year.
 * @param {string} isoDate - The ISO date string.
 * @returns {string} The formatted date string.
 */
function isoDateToItaVerboseWithDayAndNoYear(isoDate) {
    // '1970-01-01' -> 'Domenica 1 Gennaio'
    return format(new Date(isoDate), 'EEEE d MMMM', {locale: it}).replace(/\b\w/g, l => l.toUpperCase());
}

function isoDateToIta(isoDate) {
    // '1970-01-01' -> '01/01/1970'
    return formatDateToIta(parseDate(isoDate));
}

function isoDatetimeToIta(isoDatetime) {
    // '2023-04-26T10:30:00+02:00' -> '26/04/2023'
    return formatDateToIta(parseDatetime(isoDatetime));
}

function isoDatetimeToItaTime(isoDatetime) {
    // '2023-04-26T10:30:00+02:00' -> '10:30'
    return formatTimeToIta(parseDatetime(isoDatetime));
}

function isoDatetimeToItaDatetime(isoDatetime) {
    // '2023-04-26T10:30:00+02:00' -> '26/04/2023 alle ore 10:30'
    return `${isoDatetimeToIta(isoDatetime)} alle ore ${formatTimeToIta(parseDatetime(isoDatetime))}`;
}

function isoDatetimeToItaDashedDatetime(isoDatetime) {
    // '2023-04-26T10:30:00+02:00' -> '26/04/2023 - 10:30'
    return `${isoDatetimeToIta(isoDatetime)} - ${formatTimeToIta(parseDatetime(isoDatetime))}`;
}

function formatDate(dateString, options = null) {
    const dateFormatOptions = {day: '2-digit', weekday: 'short', month: '2-digit', year: 'numeric'};

    return capitalizeFirstLetter(isoDateToItaVerbose(dateString, options || dateFormatOptions));
}

function formatDateToItaVerbose(dateString) {
    // '2023-04-26T10:30:00+02:00' -> 'Venerdì 26 aprile 2023'
    const dateFormatOptions = {day: '2-digit', weekday: 'long', month: 'long', year: 'numeric'};

    return capitalizeFirstLetter(isoDateToItaVerbose(dateString, dateFormatOptions));
}

function formatDateToItaVerboseShortDay(dateString) {
    // '2023-04-26T10:30:00+02:00' -> 'Ven 26 aprile 2023'
    const dateFormatOptions = {day: '2-digit', weekday: 'short', month: 'long', year: 'numeric'};
    return capitalizeFirstLetter(isoDateToItaVerbose(dateString, dateFormatOptions));
}

function formatDateToItaVerboseShortDayOnly(dateString) {
    // '2023-04-26T10:30:00+02:00' -> 'Ven 26'
    const dateFormatOptions = {day: '2-digit', weekday: 'short'};
    return capitalizeFirstLetter(isoDateToItaVerbose(dateString, dateFormatOptions));
}

function formatDateInterval(startDate, endDate, options) {
    const start_date = isoDateToItaVerbose(startDate, options || {month: 'long', day: 'numeric'});
    const end_date = isoDateToItaVerbose(endDate, options || {month: 'long', day: 'numeric'});
    return start_date === end_date ? start_date : `${start_date} - ${end_date}`;
}

function formatDateIntervalToItaVerbose(startDate, endDate) {
    // '2024-06-11', '2025-06-13' -> '12 mesi e 1 giorno'
    if (!startDate || !endDate) {
        return '-';
    }
    const start_date = parseDate(startDate);
    const end_date = parseDate(endDate);

    // Check if the interval is negative
    const isNegative = start_date > end_date;
    const interval = isNegative
        ? intervalToDuration({start: end_date, end: start_date})
        : intervalToDuration({start: start_date, end: end_date});

    const monthsCount = interval.months + interval.years * 12;
    const months = monthsCount > 0 ? `${monthsCount} ${pluralize(monthsCount, 'mese', 'mesi')}` : '';
    const days = interval.days > 0 ? `${interval.days} ${pluralize(interval.days, 'giorno', 'giorni')}` : '';

    const intervalStrings = [months, days].filter(Boolean);
    const intervalString = intervalStrings.join(' e ');

    // Check if dates are the same
    if (interval.years === 0 && interval.months === 0 && interval.days === 0) {
        return 'stessa data';
    }

    return isNegative ? `${intervalString} (negativo)` : intervalString;
}

function isSameMonth(date1, date2) {
    // checks if dates are in the same month and year
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

function isTodayOrPast(date) {
    return isToday(date) || isPast(date);
}

function isTodayOrFuture(date) {
    return isToday(date) || isFuture(date);
}

function purgeSecondsFromTime(timeString) {
    return timeString.slice(0, -3);
}

/**
 * Sums the given hour and minutes and returns the computed time.
 *
 * @param {string} hour - The hour in the format "HH:MM".
 * @param {number} minutes - The minutes to be added.
 * @param {string} format - The format of the computed time. Default is "HH:MM".
 * @param {boolean} as_duration - If it is a duration (that can be more than 24 hours).
 * @returns {string} The computed time in the specified format.
 */
function sumHourStringAndMinutes(hour, minutes, formatString = 'HH:MM', as_duration = false) {
    const mod = (n, m) => ((n % m) + m) % m;
    const [h, m] = hour.split(':').map(Number);
    const totalMinutes = h * 60 + m;
    let computedMinutes = totalMinutes + minutes;
    if (!as_duration) {
        computedMinutes = mod(computedMinutes, 24 * 60);
    }
    const computedHour = Math.floor(computedMinutes / 60);
    const computedMinutesRemainder = computedMinutes % 60;
    return formatString
        .replace('HH', computedHour.toString().padStart(2, '0'))
        .replace('MM', computedMinutesRemainder.toString().padStart(2, '0'));
}

function formatTimeInterval(startTimeString, endTimeString) {
    const start_time = purgeSecondsFromTime(startTimeString);
    const end_time = purgeSecondsFromTime(endTimeString);
    return `${start_time} - ${end_time}`;
}

/**
 * Get Month Name from a 1-indexed month number
 * @param monthNumber {Number} - Month number from 1 to 12
 * @returns {string}
 */
function monthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1, 1); // Months are zero-indexed for setMonth
    // Setting day = 1 because not every month has 29+ days; it avoids bugs like 31/03/2024 - 1 month = 31/02/2024

    return date.toLocaleString('it-IT', {month: 'long'});
}

/**
 * Get a Capitalized Month Name from a 1-indexed month number
 * @param monthNumber {Number} - Month number from 1 to 12
 * @returns {string}
 * @see monthName
 */
function capMonthName(monthNumber) {
    return capitalizeFirstLetter(monthName(monthNumber));
}

function capDateMonthYear(dateString) {
    if (!dateString) return;

    return capitalizeFirstLetter(isoDateToItaVerbose(dateString, {month: 'long', year: 'numeric'}));
}

function currentYear() {
    return new Date().getFullYear();
}

function dateRange(from_date, to_date) {
    let _date = isValid(from_date) ? from_date : parseDate(from_date);
    const _to_date = isValid(to_date) ? to_date : parseDate(to_date);

    const date_range = [];
    while (_date <= _to_date) {
        date_range.push(_date);
        _date = addDays(_date, 1);
    }

    return date_range;
}

function isoDatetimeToItaDistance(isoDatetime) {
    return formatDistance(parseISO(isoDatetime), new Date(), {locale: it, addSuffix: true});
}

function addWorkingDays(date, workingDaysAmount) {
    // Like addDays but skipping saturdays and sundays
    let result = new Date(date);
    let addedDays = 0;
    const increment = workingDaysAmount > 0 ? 1 : -1;

    while (addedDays !== workingDaysAmount) {
        result = addDays(result, increment);
        if (!isWeekend(result)) {
            addedDays += increment;
        }
    }

    return result;
}

/**
 * Converts a full date string to a day/month format.
 *
 * @param {string} date - The date string in the format 'yyyy-MM-dd'.
 * @returns {string} - The formatted date string in the format 'dd/MM'.
 */
function isoDateToDayMonth(date) {
    return format(parseDate(date), 'dd/MM', {locale: it});
}
/**
 * Returns a date with the day and the time as dd-MM-yyyy HH:mm
 * If the date is within 7 days, returns the day name and the time as '2 giorni fa alle 12:30'
 * If the date is yesterday, returns the day as 'Ieri alle 12:30'
 * If the date is today, returns the time as 'Oggi alle 12:30'
 */
function daysFromTodayWithTime(date) {
    const inputDate = new Date(date);

    // Format time consistently
    const timeFormat = 'HH:mm';
    const formattedTime = format(inputDate, timeFormat);

    // Check specific date conditions
    if (isToday(inputDate)) {
        return `Oggi alle ${formattedTime}`;
    }

    if (isYesterday(inputDate)) {
        return `Ieri alle ${formattedTime}`;
    }

    // Check if within 7 days
    const daysDiff = differenceInDays(new Date(), inputDate);

    if (daysDiff > 0 && daysDiff <= 7) {
        return `${daysDiff} giorni fa alle ${formattedTime}`;
    }

    // Default format for dates beyond 7 days
    return format(inputDate, 'dd-MM-yyyy HH:mm');
}

function daysDifference(isoDate1, isoDate2) {
    const datetime1 = new Date(isoDate1);
    const datetime2 = new Date(isoDate2);

    const date1 = new Date(datetime1.getFullYear(), datetime1.getMonth(), datetime1.getDate());
    const date2 = new Date(datetime2.getFullYear(), datetime2.getMonth(), datetime2.getDate());

    const diffTime = date1 - date2;
    return diffTime / (1000 * 60 * 60 * 24);
}

export {
    weekDays,
    formatDateToISOWithoutTimezone,
    addWorkingDays,
    capDateMonthYear,
    capMonthName,
    currentYear,
    dateRange,
    daysDifference,
    formatDate,
    formatDateInterval,
    formatDateIntervalToItaVerbose,
    formatDateToIta,
    formatDateToItaVerboseShortDay,
    formatDateToItaVerboseShortDayOnly,
    formatDateToItaVerbose,
    formatTimeInterval,
    formatTimeToIta,
    isoDateToDayMonth,
    intervalToTimeString,
    isSameMonth,
    isToday,
    isTodayOrFuture,
    isTodayOrPast,
    isoDateToIta,
    isoDateToItaVerbose,
    isoDateToItaVerboseWithDay,
    isoDateToItaVerboseWithDayAndNoYear,
    isoDatetimeToIta,
    isoDatetimeToItaDatetime,
    isoDatetimeToItaDashedDatetime,
    isoDatetimeToItaDistance,
    sumHourStringAndMinutes,
    isoDatetimeToItaTime,
    monthName,
    parseDate,
    parseDatetime,
    purgeSecondsFromTime,
    daysFromTodayWithTime,
};
