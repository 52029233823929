/**
 * This composable must be used every time we need
 * - to create a route
 * - to access the value of companyId or employeeId in the URL
 *
 * @param {String} jetRouteEntityName The entity name: companyId or employeeId
 */
export function useJetRoute(jetRouteEntityName) {
    const route = useRoute();
    const router = useRouter();

    const jetRouteEntityValue = computed(() => route.params[jetRouteEntityName]);

    function buildJetRoute(routeLocation) {
        if (!routeLocation.params) {
            routeLocation.params = {};
        }
        routeLocation.params[jetRouteEntityName] = jetRouteEntityValue.value;
        return routeLocation;
    }

    function routeTo(routeName) {
        router.push(buildJetRoute(routeName));
    }

    function routeReplace(routeName, params = {}) {
        router.replace(buildJetRoute({name: routeName, params}));
    }

    return {
        buildJetRoute,
        jetRouteEntityValue,
        routeTo,
        routeReplace,
    };
}
