import settings from '@/env.js';

export function goToSSO(send_redirect = false) {
    if (send_redirect) {
        // If going to sso from backoffice, sending the current path to redirect after impersonification ends
        const pathname = encodeURIComponent(window.location.pathname);
        const hash = encodeURIComponent(window.location.hash); // hash includes the '#' symbol
        window.location = `${settings.SSO_URL}?post_impersonification_redirect_bo=${pathname}${hash}`;
        return;
    }
    window.location = settings.SSO_URL;
}
