import {ApiBase} from '@jetCommon/api/base.js';

export default class EmployeeApiCommon extends ApiBase {
    static resourceName = 'employees';

    choicesForAbsenceRequest(params) {
        return this.apiGet('choices_for_absence_request/', {params});
    }

    inCurrentPayroll(params) {
        return this.apiGet('in_current_payroll/', {params});
    }

    currentInForce(params) {
        return this.apiGet('current_in_force/', {params});
    }

    employeeChoices(params) {
        return this.apiGet('employee_choices/', {params});
    }

    uploadAvatar(employeeId, data) {
        return this.apiPostForm(`${employeeId}/avatar/`, data);
    }

    removeAvatar(employeeId) {
        return this.apiDelete(`${employeeId}/avatar/`);
    }

    sendInvitation(employeeId, data = {}) {
        return this.apiPost(`${employeeId}/send_invitation/`, data);
    }

    async jobTitleChoices(params) {
        return await this.apiGet('job_title_choices/', {params});
    }

    async contractKindChoices(params) {
        return await this.apiGet('contract_kind_choices/', {params});
    }

    patchWithNotification(employeeId, data) {
        return this.apiPatch(`${employeeId}/patch_and_notify/`, data);
    }

    patchCustomFieldValues(employeeId, data) {
        return this.apiPatch(`${employeeId}/`, {custom_field_values: data});
    }

    getCompanyEmployeeCustomFields(companyId, extraParams = {}) {
        return this.apiGet('custom_fields/', {params: {company: companyId, ...extraParams}});
    }

    workingHoursChoices(params) {
        return this.apiGet('working_hours_choices/', {params});
    }

    getOAuthConsentURL(employeeId, params) {
        return this.apiGet(`${employeeId}/begin_oauth_flow/`, {params});
    }

    finalizeOAuth(employeeId, oauth_query_params) {
        return this.apiPost(`${employeeId}/finalize_oauth_flow/`, oauth_query_params);
    }

    whoIsOut(params) {
        return this.apiGet('is_out_now/', {params});
    }

    revokeCalendarIntegration(employeeId) {
        return this.apiPost(`${employeeId}/revoke_calendar_integration/`);
    }
}
